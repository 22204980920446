$(function(){
	
	$(".showpasswordbutton .input-group-text").click(function(event){
		var container = $(this).closest(".showpasswordbutton");
		var inputfield = container.find("[data-input=password]")
		if( inputfield.attr("type") == "text" ){
			inputfield.attr("type", "password");
			container.find("i")
				.addClass("fa-eye-slash")
				.removeClass("fa-eye");
		} else {
			inputfield.attr("type", "text");
			container.find("i")
				.removeClass("fa-eye-slash")
				.addClass ("fa-eye");
		}
	});
	
	$("#loginroll, #registerroll").on("shown.bs.collapse", function(event){
		$([document.documentElement, document.body]).animate({
			scrollTop: $(this).find("[type=submit]").offset().top
		}, 200);
	});
	
	$("#loginroll form, #registerroll form").submit(function(event){
		event.preventDefault();
		
		$(".is-invalid").removeClass("is-invalid")
		
		var container = $(this).closest(".emailcontainer");
		
		var submitbutton = $(this).find("[type=submit]");
		submitbutton.attr("disabled", true);
		submitbutton.next().show();
		
		$.post("/auth/email", {
			"formemail": $(this).find("[data-input=email]").val(),
			"formpass": $(this).find("[data-input=password]").val(),
			"action": container.data("action")
		}, function(obj){
			var {res} = obj;
			var errormessages = {
				"emailformat": ["email", "Email entered in an incorrect format"],
				"emailnotfound": ["email", "Email not found"],
				"passnotmatch": ["password", "Wrong password"],
				
				"duplicateemail": ["password", "An account with this email already exists"],
				"passwordlength1": ["password", "Use 8 characters or more for your password"],
				"passwordlength2": ["password", "Password too long"],
				"passwordstrong": ["password", "Choose a stronger password, use a mix of letters and numbers"],
			}
			
			submitbutton.removeAttr("disabled");
			submitbutton.next().hide();
			
			if( res == "ok" ){
				window.location.replace("/");
			} else if( errormessages[res] ){
				var arr = errormessages[res];
				var el = container.find(`[data-input=${arr[0]}]`);
				el.closest(".form-group").find(".invalid-feedback").text(arr[1]);
				el.addClass("is-invalid");
			} else {
				alert(res);
			}
			
		}, "json");
	});
	
	
	$("#logintab .loginbutton").each((i, v) => {
		var el = $(v).clone();
		var button = el.find(".loginbutton-text");
		button.text(button.text().replace("Log in", "Sign up"));
		$("#registerroll").before(el);
	});
	
	$("#registertab > .emailbutton")
		.attr("id", "registeremailbutton")
		.attr("data-target", "#registerroll")
	
	
	
	if( !window.URLSearchParams ){
		
		return;
	}
	var queryparams = new URLSearchParams(window.location.search);
	if( queryparams.get("reset") ){
		$(".logincol").hide();
		$(".resetcol").show();
		
		$(".resetcol input[type=email]").val(queryparams.get("email"));
	}
	
	$(".resetcol form").submit(function(event){
		event.preventDefault();
		
		$.post("/auth/email/reset", {
			"resetstr": queryparams.get("reset"),
			"formpass": $(this).find("[data-input=password]").val(),
			"action": "reset"
		}, function(obj){
			var {res} = obj;
			
			if( res == "ok" ){
				alert("Password just been reset");
				
				window.location.replace("/login");
			} else {
				alert("error: " + res);
			}
			
		}, "json");
	});
	
});

